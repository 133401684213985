@use './rev-shared-media-size.scss' as sizes;
// .rev-responsive {
//   @media (max-width: sizes.$tablet) {
//     .section {
//       padding: 1em;
//     }

//     .rev-mobile-padding {
//       padding: 10vw 5vw;
//     }

//     .rev-mobile-margin {
//       margin: 10vw 5vw;
//     }

//     .rev-mobile-bottom-margin {
//       margin: 0 0 10vw 0;
//     }
//     .rev-mobile-bottom-margin-small {
//       margin: 0 0 5vw 0;
//     }

//     .icon {
//       width: min(6vw, 1.5rem) !important;
//       vertical-align: bottom;
//     }
//   }
// }

.rev-subtitle-responsive-font {
  font-size: min(6vw, 1.5rem) !important;
}

.rev-text-link-rounded {
  font-size: min(6vw, 1rem) !important;
}

.rev-text-example {
  font-size: min(6vw, 1rem) !important;
}

.rev-center-contents {
  * {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: sizes.$tablet) {
  .rev-tablet-button-margin {
    margin: 10px;
  }
}
